<template>
  <div>
    <b-row v-if="data">
      <b-col md="8">
        <b-row>
          <b-col md="12">
            <b-list-group flush>
              <b-list-group-item class="text-primary" disabled
                >Détails du produit <b></b
              ></b-list-group-item>
              <b-list-group-item>
                <img class="img-fluid" :src="data.file" />
              </b-list-group-item>
              <b-list-group-item>Désignation: <b>{{ data.title }}</b></b-list-group-item>
              <b-list-group-item>Prix: <b>{{ data.price }} XOF</b></b-list-group-item>
              <b-list-group-item v-if="data.category">
                Categorie: <b>{{ data.category.title }}</b> - 
                Module: <b v-if="data.category.parent">{{ data.category.parent.title }}</b>
              </b-list-group-item>
              
              <b-list-group-item>
                Disponibilite:
                <b-avatar
                  size="32"
                  :variant="`light-${
                    disponibilite(data.disponibilite).variant
                  }`"
                >
                  <feather-icon
                    :icon="disponibilite(data.disponibilite).icon"
                  />
                </b-avatar>
                <b>{{ this.disponibilite(data.disponibilite).text }}</b>
                -
                Statut:
                <b-avatar
                  size="32"
                  :variant="`light-${
                    status(data.status).variant
                  }`"
                >
                  <feather-icon
                    :icon="status(data.status).icon"
                  />
                </b-avatar>
                <b>{{ this.status(data.status).text }}</b>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="12" v-if="data.category">
            <b-list-group flush v-if="data.category.parent.slug == 'ref-soin'">
              <b-list-group-item>Type d'extraction: <b>{{ data.type_extraction }}</b></b-list-group-item>
              <b-list-group-item>Volume: <b>{{ data.volume }}</b></b-list-group-item>
              <b-list-group-item>Composante principale: <b>{{ data.composante_principale }}</b></b-list-group-item>
              <b-list-group-item>Format: <b>{{ data.format }}</b></b-list-group-item>
              <b-list-group-item>Composant naturel: <b>{{ data.composant_naturel }}</b></b-list-group-item>
              
            </b-list-group>
            <b-list-group flush v-if="data.category.parent.slug == 'ref-outil'">
              <b-list-group-item>Dimension: <b>{{ data.dimension }}</b></b-list-group-item>
              <b-list-group-item>Couleur: <b>{{ data.color }}</b></b-list-group-item>
              
            </b-list-group>
          </b-col>
          <b-col md="12">
            <b-list-group-item>Resumé: <b>{{ data.content }}</b></b-list-group-item>
            <b-list-group flush>
              <b-list-group-item
                >Date:
                <b>{{
                  this.$coddyger.cdgDateFormat(data.created_at, true)
                }}</b> - Modifié le:
                <b>{{
                  data.updated_at !== null ? this.$coddyger.cdgDateFormat(data.updated_at, true) : ''
                }}</b>
                </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col lg="12">
            <b-list-group flush v-if="data.provider">
              <b-list-group-item class="text-primary" disabled
                >Détails du demandeur <b></b
              ></b-list-group-item>
              <b-list-group-item>
                <img class="img-fluid" :src="data.provider.avatar" />
              </b-list-group-item>
              <b-list-group-item v-if="data.provider"
                >Nom:
                <b
                  >{{ data.provider.title }}</b
                ></b-list-group-item
              >
              <b-list-group-item>Contact: <b>{{ data.provider.contact }}</b></b-list-group-item>
              <b-list-group-item>WhatsApp: <b>{{ data.provider.whatsapp }}</b></b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const status = (payload) => {
      if (payload === "pending")
        return { variant: "warning", icon: "PieChartIcon", text: "En attente" };
      if (payload === "active")
        return { variant: "success", icon: "CheckCircleIcon", text: "En ligne" };
      if (payload === "denied")
        return { variant: "danger", icon: "XIcon", text: "Refusé" };
      return { variant: "primary", icon: "XIcon" };
    };

    const disponibilite = (payload) => {
      if (payload === "indisponible")
        return { variant: "danger", icon: "PieChartIcon", text: "Indisponible" };
      if (payload === "disponible")
        return { variant: "success", icon: "CheckCircleIcon", text: "Disponible" };
      return { variant: "primary", icon: "XIcon" };
    };

    return {
      status,
      disponibilite,
    }
  },
})
</script>
