<template>
  <div>
    <b-alert v-if="cantLoadData" variant="danger" show>
      <h4 class="alert-heading">Erreur</h4>
      <div class="alert-body">
        <span>{{ cantLoadDataText }}</span>
      </div>
    </b-alert>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        hide-footer
        ok-title="Fermer"
        size="lg"
      >
        <single-view :data="singleData" />
        <hr />
        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="closeModal('single')"
            >
              Fermer
            </b-button>
            <b-dropdown
              style="float: right"
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Validation"
              variant="primary"
            >
              <b-dropdown-item @click="validate(singleData, 'active')">Valider</b-dropdown-item>
              <b-dropdown-item @click="validate(singleData, 'denied')">Refuser</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
        :busy.sync="tabledata.isBusy"
      >
        <!-- Column: post Status -->
        <template #cell(status)="data">
          <b-avatar
            :id="`post-row-${data.item.slug}`"
            size="32"
            :variant="`light-${
              status(data.item.status).variant
            }`"
          >
            <feather-icon
              :icon="status(data.item.status).icon"
            />
          </b-avatar>
          {{ status(data.item.status).text }}
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner small type="grow" />
            <strong> Veuillez patienter...</strong>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAlert,
  BAvatar,
  BDropdown,
    BDropdownItem,
    BDropdownDivider,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import ProductStore from '@/store/app/product/'
import * as payloads from './payloads.json'
import SingleView from './components/single-view.vue'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAlert,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,

    vSelect,
    SingleView,
  },
  data() {
    return {
      cantLoadData: false,
      cantLoadDataText: payloads.cantLoadDataText,
      payloads: {},
      isLoading: false,
      singleData: [], // For single view mode
      locale: 'fr',
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        columns: [
          {
            key: 'index',
            label: '#',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'title',
            label: 'Titre',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'price',
            label: 'Prix',
            sortable: true,
            formatter: val => `${val} Frs`,
          },
          {
            key: 'category',
            label: 'Categorie',
            sortable: true,
            formatter: (val) => `${val !== null ? `${val.title}` : ""}`,
          },
          {
            key: 'provider',
            label: 'Fournisseur',
            sortable: true,
            formatter: (val) => `${val !== null ? `${val.title}` : ""}`,
          },
          {
            key: 'status',
            label: 'Status',
            sortable: true,
            formatter: (val) => `${val !== null ? `${val.title}` : ""}`,
          },
          {
            key: "created_at",
            label: "Enregistré le",
            sortable: true,
            formatter: (val) => `${val !== null ? `${this.$coddyger.cdgDateFormat(val)}` : ""}`,
          },
          { key: 'actions' },
        ],
        searchTerm: '',
      },
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        // -FORM-PROPERTIES-
        title: '',
        price: 0,
        content: '',
        
        type_extraction: '',
        dimension: '',
        color: '',
        file: null,
        
        category: '',
        provider: '',

        typeExtractionList: payloads.typeExtraction,
        status: payloads.status,
        options: {
          date: {
            date: true,
            delimiter: '-',
            datePattern: ['d', 'm', 'Y'],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      },
    }
  },
  computed: {
    buildData() {
      const { data } = this.$store.state.ProductStore
      this.tabledata.totalRows = data === undefined ? 0 : data.length

      return data === undefined ? [] : data
    },
    buildDataCategory() {
      const { category } = this.$store.state.ProductStore

      return category === undefined ? [] : category
    },
    buildDataProvider() {
      const { provider } = this.$store.state.ProductStore

      return provider === undefined ? [] : provider
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore()
    // Build payloads
    this.buildPayloads()
    // Build store data
    this.buildStoreData()

    // Build foreigners
    this.buildForeigners()
  },

  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!ProductStore.isRegistered) {
        this.$store.registerModule('ProductStore', ProductStore)
        ProductStore.isRegistered = true
      }
    },
    /* ----------------------------------------
    | APP PAYLOADS
      ---------------------------------------- */
    buildPayloads() {
      let { path } = this.$router.history.current
      const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
      path = getLastItem(path)
      const parent = 'product'

      this.payloads = {
        route: `apps-${path}`,
        paths: {
          select: `/api/${parent}/select`,
          remove: `/api/${parent}/remove`,
        }
      }
    },
    buildStoreData() {
      this.tabledata.isBusy = true;
      this.$store.dispatch('ProductStore/fetch', this.payloads.paths.select).then(() => {
          this.tabledata.isBusy = false;
        }).catch(() => {
          this.tabledata.isBusy = false;
          this.cantLoadData = true;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erreur",
              icon: "WarningIcon",
              variant: "danger",
              text: "Impossible de charger la liste des données. Merci de réessayer plutard.",
            },
          });
        });
    },
    buildForeigners() {
      
    },

    emptyFileInput() {
      this.formdata.file = null
    },
    closeModal(object) {
      if (object === 'save') {
        this.$refs.setDataModal.hide()
        
        this.resetValues()
      } else if (object === 'edit') {
        this.$refs.editModal.hide()
        this.resetValues()
      } else if (object === 'single') {
        this.$refs.singleDataModal.hide()
        this.resetValues()
      } else {
        this.$refs.importModal.hide()
      }
    },
    openSaveDialog() {
      this.$refs.setDataModal.show()
    },
    openImportDialog() {
      this.$refs.importModal.show()
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    openDialog(data) {
      this.$refs.singleDataModal.show()
      this.singleData = data
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: { ...this.$route.query, pid: id },
      })
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprimer cet enregistrement ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.deleteData()
          } else {
            this.revertUrl()
          }
        })
    },
    deleteData() {
      const id = this.$route.query.pid
      if (id !== '' || id !== null) {
        this.$store
          .dispatch('ProductStore/remove', {
            item: id,
            path: this.payloads.paths.remove,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Action Réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Enregistrement supprimé avec succès!',
              },
            })
            this.revertUrl()
          })
          .catch(error => {
            this.swalError('Erreur', error.message, 'error')
          })
      }
    },
    // REVERT URL TO THE PREVIOUS
    revertUrl() {
      this.$router.replace({ name: this.payloads.route })
    },
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null
      this.formdata.title = ''
      this.formdata.price = 0
      
      this.formdata.content = ''
      this.formdata.dimension = ''
      this.formdata.color = ''
      this.formdata.type_extraction = []

      this.formdata.category = []
      this.formdata.provider = []

      this.formdata.file = null
      this.modaldata.data = {}
    },
    validate(payloads, action) {
      if (payloads === '') {
        this.swalError('Erreur', this.buildRequiredMessage('titre'), 'error')
      } else {
        payloads.action = action;
        this.$store.dispatch('ProductStore/validate', payloads).then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Action Réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Validation éffectuée avec succès!',
                },
              })
              // this.$refs.singleDataModal.hide()
            }).catch(error => {
              this.isLoading = false
              console.log(error)

              this.swalError('Erreur', error.message, 'error')
            })
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;
    const status = (payload) => {
      if (payload === "pending")
        return { variant: "warning", icon: "PieChartIcon", text: "En attente" };
      if (payload === "active")
        return { variant: "success", icon: "CheckCircleIcon", text: "En ligne" };
      if (payload === "denied")
        return { variant: "danger", icon: "XIcon", text: "Refusé" };
      return { variant: "primary", icon: "XIcon" };
    };

    return {
      appName,
      appLogoImage,
      appDesc,
      status,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
